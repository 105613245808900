.App {
  text-align: center;
}

.Play {
  background-image: url("strings.svg");
  background-repeat: repeat-y;
  min-height: 100vh;
  min-width: 100%;
  background-size: contain;
  background-position: center;
  
}

.svg {
  margin: 30px;
  padding: 20px;
}

.main_title_img {
  margin: 15px;
  max-height: 15vh;
}

.main_title_img_2 {
  margin: 15px;
  max-height: 10vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #533A4F;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none; 
}

h1 {
  margin-top: 0px;
  padding-top: 30px;
}
h1, p {
  padding-left: 10px;
  padding-right: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

button, .link-button, input[type=file]::file-selector-button {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

button:hover:not([disabled]), .link-button:hover, input[type=file]::file-selector-button:hover {
  background-color: transparent;
  color: #fff;
}

button:disabled {
  opacity: 0.5; 
  cursor: not-allowed; 
}

.upload-sec {
  padding: 10px;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
  margin: 10px;
}